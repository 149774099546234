<template>
  <v-card>
    <v-container>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="generarConcepto()"
      >
        <v-data-table
          :headers="headers"
          :items="itemsGenerarConcepto"
          class="elevation-1"
          item-key="fecha"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row class="pb-0">
                <v-col cols="12" align="start">
                  <v-card-title class="pl-1 primary--text"
                    >{{ title }}
                  </v-card-title>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row>
              <!-- Concepto -->
              <v-col cols="8" md="4" class="m-3">
                <v-autocomplete
                  v-model="conceptoSelected"
                  :items="conceptos"
                  label="Concepto"
                  :rules="rules.required"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalGenerarConcepto()"> Cerrar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingGenerarConcepto"
          form="form"
          color="primary"
        >
          Generar concepto
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  components: { PageHeader, DeleteDialog },
  name: "GenerarConceptoADevengar",
  props: {
    registrosSeleccionados: {
      type: Array,
      required: true,
    },
    fechaDesde: {
      type: String,
      required: true,
    },
    fechaHasta: {
      type: String,
      required: true,
    },
    idAgente: {
      type: Number,
    },
  },
  data() {
    return {
      title: "Generación de conceptos a devengar",
      itemsGenerarConcepto: [],
      itemsParaAgrupar: [],
      conceptoSelected: null,
      isFormValid: false,
      rules: rules,
      respo: "",
      conceptos: [],
      headers: [
        {
          text: "N° Agente de cuenta",
          value: "agectaId",
          sortable: false,
        },
        {
          text: "Agente de cuenta",
          value: "agectaNom",
          sortable: false,
        },
        {
          text: "Grupo",
          value: "benGrId",
          sortable: false,
        },
        {
          text: "Período",
          value: "periodo",
          sortable: false,
        },
        {
          text: "Importe",
          value: "importeString",
          align: "end",
          sortable: false,
        },
      ],
      loading: false,
      loadingGenerarConcepto: false,
    };
  },
  created() {
    this.setSelects();
    this.itemsParaAgrupar = this.registrosSeleccionados;
    this.loadGeneracionConcepto();
    this.idAgente;
    this.fechaDesde;
    this.fechaHasta;
  },
  methods: {
    ...mapActions({
      agrupaEncautoInteresesACobrar:
        "configuracion/agrupaEncautoInteresesACobrar",
      grabaProcesoInteresesACobrar:
        "configuracion/grabaProcesoInteresesACobrar",
      getConceptos: "devengamientos/getConceptos",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
    },
    closeModalGenerarConcepto() {
      this.$emit("closeModalGenerarConcepto");
    },
    closeModalGenerarConceptoAndReload() {
      this.$emit("closeModalGenerarConceptoAndReload");
    },
    async loadGeneracionConcepto() {
      this.loading = true;
      this.respo = await this.agrupaEncautoInteresesACobrar(
        this.registrosSeleccionados
      );
      this.itemsGenerarConcepto = this.respo;
      this.loading = false;
    },
    async generarConcepto() {
      this.loadingGenerarConcepto = true;
      const data = {
        conceptosAGenerar: this.itemsGenerarConcepto,
        parametrosGeneraConcepto: {
          agectaId: this.idAgente != null ? this.idAgente : 0,
          conceptoId: this.conceptoSelected,
          fecDesde: this.fechaDesde,
          fecHasta: this.fechaHasta,
        },
      };
      const res = await this.grabaProcesoInteresesACobrar(data);
      let errores = res.find((item) => item.idError > 0);
      if (!errores)
        this.setAlert({
          type: "success",
          message: "El concepto a devengar se ha generado con éxito",
        });
      else {
        this.setAlert({
          type: "warning",
          message: errores.mensaje,
        });
      }
      this.closeModalGenerarConceptoAndReload();
      this.$router.push({ name: "DevengamientoCosegurosWeb" });
      this.loadingGenerarConcepto = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
