var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.generarComprobante()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsGenerarComprobante,"item-key":"fecha","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.agectaId",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.agectaId)+" ")])]}},{key:"item.agectaNom",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.agectaNom)+" ")])]}},{key:"item.osNom",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.osNom)+" ")])]}},{key:"item.tCompNom",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.tCompNom)+" ")])]}},{key:"item.coseCtaCteSuc",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.coseCtaCteSuc)+" ")])]}},{key:"item.numeroOrden",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.numeroOrden)+" ")])]}},{key:"item.coseTotalString",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.tCompNom == '' || item.coseCtaCteSuc == null
                ? 'color:red'
                : '')},[_vm._v(" "+_vm._s(item.coseTotalString)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title)+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de emisión","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat(
                        _vm.rules.validDate,
                        new Date(_vm.parseDateToIso(_vm.fechaEmision)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaVencimiento)) ||
                          'Formato incorrecto'
                      )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menu1 = false},"blur":function($event){[(_vm.fechaEmi = _vm.parseDateToIso(_vm.fechaEmision))]},"change":function($event){[(_vm.fechaEmi = _vm.parseDateToIso(_vm.fechaEmision))]}},model:{value:(_vm.fechaEmision),callback:function ($$v) {_vm.fechaEmision=$$v},expression:"fechaEmision"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[(_vm.fechaEmision = _vm.formatDate(_vm.fechaEmi))]},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaEmi),callback:function ($$v) {_vm.fechaEmi=$$v},expression:"fechaEmi"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de vto.","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.required.concat(
                        _vm.rules.validDate,
                        new Date(_vm.parseDateToIso(_vm.fechaEmision)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaVencimiento)) ||
                          'Formato incorrecto'
                      ),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menu2 = false},"blur":function($event){[(_vm.fechaVenc = _vm.parseDateToIso(_vm.fechaVencimiento))]},"change":function($event){[(_vm.fechaVenc = _vm.parseDateToIso(_vm.fechaVencimiento))]}},model:{value:(_vm.fechaVencimiento),callback:function ($$v) {_vm.fechaVencimiento=$$v},expression:"fechaVencimiento"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[(_vm.fechaVencimiento = _vm.formatDate(_vm.fechaVenc))]},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaVenc),callback:function ($$v) {_vm.fechaVenc=$$v},expression:"fechaVenc"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{ref:"periodoD",attrs:{"label":"Período","outlined":"","dense":"","clearable":"","autocomplete":"not","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(_vm.rules.periodoYyyyMm),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1)],1)]},proxy:true}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModalGenerarComprobante()}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.loadingGenerarComprobante,"form":"form","color":"primary"}},[_vm._v(" Generar comprobante ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }